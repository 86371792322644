import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";
import './agency-partner-thank-yous.scss';


export default ({location}) => {

    return (<ThankYouPage location={location}>
            <h1>Thank you!</h1>

            <p>We appreciate your interest in our Agency Partner Program.</p>
            <a className="link" href="https://prismic-io.s3.amazonaws.com/amazeeio/3d2bcf2e-4e37-4f0a-ba3a-8789c9649b8d_amazee-io+Partner+Program+2022.pdf" target="_blank">Click here to Download the Brochure now!</a>
            <p>The brochure is also on its way to your Inbox.</p>
            <p><a className="link" href="/agency-partners">Return to Agency Partners</a> &nbsp;&nbsp; | &nbsp;&nbsp; <a className="link" href="/agency-partners/team-up-with-us">Team up with us</a></p>
        </ThankYouPage>
    );
}